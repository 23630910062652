<template>
  <div class="supervise-flow">

    <el-form inline>
      <el-form-item label="身份" prop="identityGuid">
        <el-select v-model="searchParams.identityGuid" clearable>
          <el-option v-for="identity of identityList" :key="identity.identityGuid" :label="identity.identityName"
                     :value="identity.identityGuid"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearchClick" :loading="searchLoading">查询</el-button>
        <el-button @click="handleAddClick">添加</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="list" border header-cell-class-name="bg-info text-light">
      <el-table-column align="center" prop="superviseFlowName" label="监理计量流程名称"></el-table-column>
      <el-table-column align="center" prop="stepName" label="步骤名称"></el-table-column>
      <el-table-column :formatter="identityFormatter" align="center" prop="identityGuid" label="身份"></el-table-column>
      <el-table-column :formatter="nextUsersFormatter" align="center" prop="nextUsers"
                       label="下步身份列表"></el-table-column>
      <el-table-column align="center" prop="sortId" label="排序"></el-table-column>
      <el-table-column align="center" label="操作">
        <template v-if="!!scope.row.superviseFlowGuid" slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleEditClick(scope.row)">编辑</el-button>
          <el-button type="danger" size="mini" @click="handleDeleteClick(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="formDialogTitle" :visible.sync="formDialogVisible" destroy-on-close>
      <el-form :model="superviseFlow" label-width="170px">
        <el-form-item label="监理计量流程名称" prop="superviseFlowName">
          <el-input v-model="superviseFlow.superviseFlowName" placeholder="监理计量流程名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="步骤名称" prop="stepName">
          <el-input v-model="superviseFlow.stepName" placeholder="步骤名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="下步人员身份列表" prop="nextUserList">
          <el-select v-model="superviseFlow.nextUserList" multiple placeholder="下步人员身份列表" clearable>
            <el-option v-for="identity of identityList" :key="identity.identityGuid" :label="identity.identityName"
                       :value="identity.identityGuid"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="身份" prop="identityGuid">
          <el-select v-model="superviseFlow.identityGuid" placeholder="身份" clearable>
            <el-option v-for="identity of identityList" :key="identity.identityGuid" :label="identity.identityName"
                       :value="identity.identityGuid"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sortId">
          <el-input-number v-model="superviseFlow.sortId" :min="0" :step="1" step-strictly
                           placeholder="排序"></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleOkClick" :loading="okLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import config from '@/config'
import utility from '@/common/utility'
import auth from '@/common/auth'

export default {
  name: 'SuperviseFlow',
  components: {},
  data () {
    return {
      superviseFlow: {
        superviseFlowGuid: '',
        superviseFlowName: '',
        stepName: '',
        nextUsers: '',
        nextUserList: [],
        identityGuid: '',
        projectGuid: '',
        sortId: ''
      },
      searchParams: {
        identityGuid: ''
      },
      identityList: [],
      isAdd: false,
      isEdit: false,
      formDialogVisible: false,
      okLoading: false,
      searchLoading: false,
      list: []
    }
  },
  computed: {
    formDialogTitle () {
      if (this.isAdd) {
        return '添加监理计量流程'
      } else if (this.isEdit) {
        return '编辑监理计量流程'
      } else {
        return ''
      }
    }
  },
  methods: {
    async handleSearchClick () {
      await this.getData()
    },
    handleAddClick () {
      this.superviseFlow = {
        superviseFlowGuid: '',
        superviseFlowName: '',
        stepName: '',
        nextUsers: '',
        nextUserList: [],
        identityGuid: '',
        projectGuid: '',
        sortId: ''
      }
      this.isEdit = false
      this.isAdd = true
      this.formDialogVisible = true
    },
    handleEditClick (row) {
      this.superviseFlow = { ...row, nextUserList: row.nextUsers.split(',') }
      console.log(this.superviseFlow)
      this.$nextTick(() => {
        this.isEdit = true
        this.isAdd = false
        this.formDialogVisible = true
      })
    },
    handleDeleteClick (row) {
      this.$confirm(`您确定要删除吗${row.superviseFlowName}中的${row.stepName}步骤吗？`)
        .then(async () => {
          const response = await axios.delete(`${config.restHost}/superviseFlows/${row.superviseFlowGuid}`, {
            headers: {
              Authorization: utility.getAuthHeader(auth.getToken())
            }
          })
          if (response.status === 200) {
            this.$message.success('删除成功')
          } else {
            this.$message.error('删除失败')
          }
          await this.getData()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleCancel () {
      this.isAdd = false
      this.isEdit = false
      this.formDialogVisible = false
    },
    async handleOkClick () {
      this.okLoading = true
      if (this.isAdd) {
        await this.add()
        this.isAdd = false
        this.formDialogVisible = false
      } else if (this.isEdit) {
        await this.edit()
        this.isEdit = false
        this.formDialogVisible = false
      }
      await this.getData()
      this.okLoading = false
    },
    async add () {
      this.superviseFlow.projectGuid = auth.getUserInfo().projectGuid
      this.superviseFlow.nextUsers = this.superviseFlow.nextUserList.join(',')

      const response = await axios.post(`${config.restHost}/superviseFlows/${this.superviseFlow.projectGuid}`, this.superviseFlow, {
        headers: {
          Authorization: utility.getAuthHeader(auth.getToken())
        }
      })

      if (response.status === 200) {
        this.$message.success('添加成功')
      } else {
        this.$message.error('添加失败')
      }
    },
    async edit () {
      this.superviseFlow.projectGuid = auth.getUserInfo().projectGuid
      this.superviseFlow.nextUsers = this.superviseFlow.nextUserList.join(',')

      const response = await axios.put(`${config.restHost}/superviseFlows/${this.superviseFlow.superviseFlowGuid}`, this.superviseFlow, {
        headers: {
          Authorization: utility.getAuthHeader(auth.getToken())
        }
      })

      if (response.status === 200) {
        this.$message.success('编辑成功')
      } else {
        this.$message.error('编辑失败')
      }
    },
    async getData () {
      this.searchLoading = true
      const response = await axios.get(`${config.restHost}/superviseFlows/${auth.getUserInfo().projectGuid}`, {
        params: { ...this.searchParams },
        headers: {
          Authorization: utility.getAuthHeader(auth.getToken())
        }
      })
      this.list = response.data
      this.searchLoading = false
    },
    async prepare () {
      const identityListResponse = await axios.get(`${config.serviceHost}/identity`, {
        headers: {
          Authorization: utility.getAuthHeader(auth.getToken())
        }
      })
      this.identityList = identityListResponse.data.data
    },
    identityFormatter (row, column, cellValue) {
      const identity = this.identityList.find(item => item.identityGuid === cellValue)
      return identity ? identity.identityName : ''
    },
    nextUsersFormatter (row, column, cellValue) {
      console.log(cellValue, cellValue.toString().split(','))
      const identityList = this.identityList.filter(item => cellValue.toString().split(',').includes(item.identityGuid))
      return identityList.map(item => item.identityName).join('\n')
    }
  },
  async created () {
    this.$loading({})
    await this.prepare()
    await this.getData()
    this.$loading({}).close()
  }
}
</script>

<style scoped>
/deep/ .el-table .cell {
  white-space: pre-line !important;
}
</style>
