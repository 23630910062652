<template>
  <div class="supervise-report">
    <div class="report-wrapper">
      <template v-for="report of reportList" >
        <div class="report-item" :key="report.alias">
          <div class="bg-info">
            <i :class="report.className"></i>
          </div>
          <div class="buttons-wrapper">
          <span style="font-size: 14px; padding-bottom: 2px;" @click="handleShowReport(report)">
            {{ report.alias }} {{ report.name }}
          </span>
            <!--          <div class="icons btn-group btn-group-sm">-->
            <!--            <button class="btn">-->
            <!--              <i class="fas fa-file-pdf"></i>-->
            <!--            </button>-->
            <!--          </div>-->
          </div>
        </div>
      </template>
    </div>
    <my-dialog
      ref="myDialog"
      :title="dialogTitle"
      :hide-ok="true"
      cancel-text="关闭"
      body-height="700px"
      size="xl">
      <iframe ref="myIFrame" :src="reportUrl" width="100%" height="100%" scrolling="no" frameborder="0"></iframe>
    </my-dialog>
  </div>
</template>

<script>
import MyDialog from '@/components/MyDialog/MyDialog.vue'
import axios from 'axios'
import auth from '@/common/auth'

export default {
  name: 'SuperviseReport',
  components: { MyDialog },
  data () {
    return {
      superviseExaminationGuid: this.$route.query.superviseExaminationGuid,
      dialogTitle: '',
      reportUrl: '',
      reportList: [
        {
          name: '工程支付月报封皮',
          alias: 'Z0',
          icon: 'iconP',
          type: 'P',
          url: null,
          className: 'iconfont iconP'
        },
        {
          name: '付款申请',
          alias: 'A25',
          icon: 'iconA',
          type: 'A',
          url: null,
          className: 'iconfont iconA'
        },
        {
          name: '监理服务费支付审核表',
          alias: 'F01',
          icon: 'iconF',
          type: 'F',
          url: null,
          className: 'iconfont iconF'
        },
        {
          name: '中期支付证书',
          alias: 'F02',
          icon: 'iconP',
          type: 'P',
          url: null,
          className: 'iconfont iconF'
        },
        {
          name: '清单支付报表',
          alias: 'F03',
          icon: 'iconF',
          type: 'F',
          url: null,
          className: 'iconfont iconF'
        },
        {
          name: '业主通知（支付单）',
          alias: 'F07',
          icon: 'iconF',
          type: 'F',
          url: null,
          className: 'iconfont iconF'
        }
      ]
    }
  },
  computed: {
    reportHost () {
      return auth.getUserInfo().projectGuid === 'A5AFD7F0-ADF8-4DEA-9D2E-248DAA8D4E2C' ? 'http://47.93.222.187:5050' : 'http://47.93.222.187:5036'
    }
  },
  methods: {
    async handleShowReport (report) {
      this.dialogTitle = report.name
      const response = await axios.get(`${this.reportHost}/superviseReports/${report.alias.toLocaleLowerCase()}/${this.superviseExaminationGuid}`)
      this.reportUrl = `${this.reportHost}/static/${response.data}`
      this.$refs.myDialog.open()
    }
  },
  async created () {
  }
}
</script>

<style scoped lang="scss">
.report-wrapper {
  clear: both;
  display: grid;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-gap: 3px 30px;
  margin-bottom: 10px;
  padding-top: 30px;
  position: relative;

  .title {
    line-height: 30px;
    position: absolute;
    top: -15px;
    left: 30px;
    width: 60px;
    background-color: white;
    text-align: center;
  }
}

.report-item {
  width: 300px;
  display: flex;
  flex-direction: row;
  margin: 0 18px 18px 0;
  margin-left: 0;
  margin-right: 15px;
  padding: 7px;
  cursor: pointer;
  border: 1px solid #eee;
  border-radius: 10px;
  box-shadow: 0 0 5px #eee;
}

.bg-info {
  width: 64px;
  height: 64px;
  line-height: 64px;
  color: white;
  text-align: center;
  border-radius: 10px;
}

.report-item i {
  font-size: 30px;
}

.report-item:hover {
  color: rgb(30, 170, 192);
}

.buttons-wrapper {
  position: relative;
  box-sizing: border-box;
  width: 220px;
  padding-left: 15px;
  padding-top: 3px;
  display: flex;
  flex-direction: column;
}

.btn {
  border-right: 1px solid #ddd;
}

.btn:last-child {
  border-right: 0;
}

.btn:hover {
  background-color: #ddd;
}

.btn i {
  font-size: 14px;
}

.icons {
  position: absolute;
  height: 32px;
  border-radius: 5px;
  line-height: 32px;
  box-sizing: border-box;
  background-color: rgb(244, 244, 244);
  border: 1px solid #ddd;
  top: 30px;
  right: 4px;

  button {
    width: 32px;
  }
}

::v-deep .el-input-number {
  width: 50px !important;
  display: inline-block;
}

::v-deep .el-input-number .el-input {
  display: inline-block;
  width: 50px;
}

.lingbao {
  width: 300px;
}
</style>
