<template>
  <div class="supervise-examination">

    <el-form inline>
      <el-form-item label="标段" prop="tenderGuid">
        <el-select v-model="searchParams.tenderGuid" clearable>
          <el-option v-for="tender of tenderList" :key="tender.tenderGuid" :label="tender.tenderName"
                     :value="tender.tenderGuid"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="工期" prop="supervisePeriodSerialNo">
        <el-input-number v-model="searchParams.supervisePeriodSerialNo" :min="0" step-strictly :step="1"
                         clearable></el-input-number>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearchClick" :loading="searchLoading">查询</el-button>
        <el-button @click="handleAddClick">添加</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="list" border header-cell-class-name="bg-info text-light">
      <el-table-column align="center" prop="superviseExaminationName" label="监理计量期数名称"></el-table-column>
      <el-table-column align="center" prop="tender.tenderName" label="标段"></el-table-column>
      <el-table-column align="center" prop="supervisePeriod.supervisePeriodName" label="工期"></el-table-column>
      <el-table-column align="center" prop="approvalUser.userName" label="当前审批人"></el-table-column>
      <el-table-column align="center" prop="superviseFlow.stepName" label="当前步骤"></el-table-column>
      <el-table-column align="center" prop="approvalStatusName" label="审批状态"></el-table-column>
      <el-table-column align="center" prop="createTime" label="创建时间"></el-table-column>
      <el-table-column align="center" width="300px" label="操作">
        <template v-if="!!scope.row.superviseExaminationGuid" slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleTransferClick(scope.row)">传送</el-button>
          <el-button v-if="scope.row.couldFinalApproval === 1" type="primary" size="mini"
                     @click="handleFinalApprovalClick(scope.row)">最终批复
          </el-button>
          <el-button v-if="scope.row.couldUndoFinalApproval === 1" type="primary" size="mini"
                     @click="handleUndoFinalApprovalClick(scope.row)">取消最终批复
          </el-button>
          <el-button type="primary" size="mini" @click="handleDetailClick(scope.row)">明细</el-button>
          <el-button type="primary" size="mini" @click="handleOtherClick(scope.row)">其他</el-button>
          <el-button type="primary" size="mini" @click="handleGenerateReportClick(scope.row)">数据汇总</el-button>
          <el-button type="primary" size="mini" @click="handleCheckReportClick(scope.row)">查看报表</el-button>
          <el-button type="danger" size="mini" @click="handleDeleteClick(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="formDialogTitle" :visible.sync="formDialogVisible" destroy-on-close>
      <el-form ref="formRef" :model="superviseExamination" :rules="rules" label-width="170px">
        <el-form-item label="工期" prop="supervisePeriodGuid">
          <el-select v-model="superviseExamination.supervisePeriodGuid" placeholder="工期" clearable>
            <el-option v-for="supervisePeriod of supervisePeriodList" :key="supervisePeriod.supervisePeriodGuid"
                       :label="supervisePeriod.supervisePeriodName"
                       :value="supervisePeriod.supervisePeriodGuid"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleOkClick" :loading="okLoading">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="下一步" :visible.sync="transferDialogVisible" destroy-on-close>
      <el-form ref="transferFormRef" :model="transferData" :rules="transferRules">
        <el-form-item prop="nextUserGuid">
          <el-cascader v-model="transferData.nextUser" :options="nextUserOptions"
                       :props="{value: 'guid', label: 'name'}" placeholder="请选择下一步审批人"
                       clearable></el-cascader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleTransferCancel">取 消</el-button>
        <el-button type="primary" @click="handleTransferOkClick" :loading="transferOkLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import config from '@/config'
import utility from '@/common/utility'
import auth from '@/common/auth'

export default {
  name: 'SuperviseExamination',
  components: {},
  data () {
    return {
      superviseExamination: {
        tenderGuid: auth.getUserInfo().tenderGuid,
        supervisePeriodGuid: ''
      },
      searchParams: {
        tenderGuid: '',
        supervisePeriodSerialNo: ''
      },
      rules: {
        supervisePeriodGuid: [
          { required: true, message: '请选择工期', trigger: 'blur' }
        ]
      },
      tenderList: [],
      supervisePeriodList: [],
      isAdd: false,
      formDialogVisible: false,
      transferDialogVisible: false,
      transferRules: {},
      transferOkLoading: false,
      okLoading: false,
      searchLoading: false,
      list: [],
      nextUserOptions: [],
      transferData: {
        nextUser: []
      }
    }
  },
  computed: {
    formDialogTitle () {
      if (this.isAdd) {
        return '添加监理计量'
      } else {
        return ''
      }
    },
    computeUrl () {
      return auth.getUserInfo().projectGuid === 'A5AFD7F0-ADF8-4DEA-9D2E-248DAA8D4E2C' ? 'beihuanb/generateReports' : 'generateReports'
    }
  },
  methods: {
    async handleFinalApprovalClick (row) {
      try {
        await axios.post(`${config.restHost}/superviseExaminations/finalApproval/${row.superviseExaminationGuid}`, {}, {
          headers: {
            Authorization: utility.getAuthHeader(auth.getToken())
          }
        })
        this.$message.success('最终批复成功')
      } catch (error) {
        this.$message.error(error.response?.data || '最终批复失败')
      } finally {
        await this.getData()
      }
    },
    async handleUndoFinalApprovalClick (row) {
      try {
        await axios.post(`${config.restHost}/superviseExaminations/undoFinalApproval/${row.superviseExaminationGuid}`, {}, {
          headers: {
            Authorization: utility.getAuthHeader(auth.getToken())
          }
        })
        this.$message.success('取消最终批复成功')
      } catch (error) {
        this.$message.error(error.response?.data || '取消最终批复失败')
      } finally {
        await this.getData()
      }
    },
    async handleTransferClick (row) {
      try {
        const response = await axios.get(`${config.restHost}/superviseExaminations/nextUsers/${row.superviseExaminationGuid}`, {
          headers: {
            Authorization: utility.getAuthHeader(auth.getToken())
          }
        })
        this.transferData = {
          superviseExaminationGuid: row.superviseExaminationGuid,
          nextUser: []
        }
        this.nextUserOptions = response.data
        this.transferDialogVisible = true
      } catch (error) {
        this.$message.error(error.response?.data || '当前不可传送')
      }
    },
    handleTransferCancel () {
      this.transferDialogVisible = false
    },
    async handleTransferOkClick () {
      if (!this.transferData.nextUser || this.transferData.nextUser.length !== 2) {
        this.$message.error('请选择下一步审批人')
      } else {
        this.transferOkLoading = true
        await this.transfer()
        this.transferDialogVisible = false
        this.transferOkLoading = false
        await this.getData()
      }
    },
    async transfer () {
      try {
        await axios.post(`${config.restHost}/superviseExaminations/transfer/${this.transferData.superviseExaminationGuid}`, {
          nextUserGuid: this.transferData.nextUser[1],
          nextFlowGuid: this.transferData.nextUser[0]
        }, {
          headers: {
            Authorization: utility.getAuthHeader(auth.getToken())
          }
        })

        this.$message.success('传送成功')
      } catch (error) {
        this.$message.error(error.response?.data || '传送失败')
      }
    },
    async handleGenerateReportClick (row) {
      try {
        await axios.post(`${config.restHost}/superviseExaminations/${this.computeUrl}/${row.superviseExaminationGuid}`, {}, {
          headers: {
            Authorization: utility.getAuthHeader(auth.getToken())
          }
        })
        this.$message.success('数据汇总成功')
      } catch (error) {
        this.$message.error(error.response?.data || '数据汇总失败')
      }
    },
    async handleSearchClick () {
      await this.getData()
    },
    async handleAddClick () {
      await this.getTenderPeriods()
      this.superviseExamination = {
        tenderGuid: auth.getUserInfo().tenderGuid,
        supervisePeriodGuid: ''
      }
      this.isAdd = true
      this.formDialogVisible = true
    },
    handleDetailClick (row) {
      this.$router.push({
        path: `/superviseExaminationRecord?superviseExaminationGuid=${row.superviseExaminationGuid}&tenderGuid=${row.tenderGuid}`
      })
    },
    handleOtherClick (row) {
      this.$router.push({
        path: `/superviseOtherRecord?superviseExaminationGuid=${row.superviseExaminationGuid}&tenderGuid=${row.tenderGuid}`
      })
    },
    handleCheckReportClick (row) {
      this.$router.push({
        path: `/superviseReport?superviseExaminationGuid=${row.superviseExaminationGuid}`
      })
    },
    handleDeleteClick (row) {
      this.$confirm(`您确定要删除吗${row.superviseExaminationName}吗？`)
        .then(async () => {
          this.$loading({})
          try {
            await axios.delete(`${config.restHost}/superviseExaminations/${row.superviseExaminationGuid}`, {
              headers: {
                Authorization: utility.getAuthHeader(auth.getToken())
              }
            })
            this.$message.success('删除成功')
          } catch (error) {
            this.$message.error(error.response?.data || '删除失败')
          } finally {
            this.$loading({}).close()
            await this.getData()
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleCancel () {
      this.isAdd = false
      this.formDialogVisible = false
    },
    async handleOkClick () {
      await this.$refs.formRef.validate(async valid => {
        if (!valid) {
          this.$message.error('请检查输入项')
        } else {
          this.okLoading = true
          await this.add()
          this.isAdd = false
          this.formDialogVisible = false
          await this.getData()
          this.okLoading = false
        }
      })
    },
    async add () {
      try {
        await axios.post(`${config.restHost}/superviseExaminations`, this.superviseExamination, {
          headers: {
            Authorization: utility.getAuthHeader(auth.getToken())
          }
        })

        this.$message.success('添加成功')
      } catch (error) {
        this.$message.error(error.response?.data || '添加失败')
      }
    },
    async getData () {
      this.searchLoading = true
      const response = await axios.get(`${config.restHost}/superviseExaminations`, {
        params: { ...this.searchParams, supervisePeriodSerialNo: this.searchParams.supervisePeriodSerialNo || '' },
        headers: {
          Authorization: utility.getAuthHeader(auth.getToken())
        }
      })
      this.list = response.data
      this.searchLoading = false
    },
    async getTenderPeriods () {
      if (!auth.getUserInfo().tenderGuid) {
        return
      }
      try {
        const supervisePeriodResponse = await axios.get(`${config.restHost}/supervisePeriods/tenderPeriods/${auth.getUserInfo().tenderGuid}`, {
          headers: {
            Authorization: utility.getAuthHeader(auth.getToken())
          }
        })
        this.supervisePeriodList = supervisePeriodResponse.data
      } catch (error) {
        this.$message.error(error.response?.data || '获取工期失败')
      }
    },
    async prepare () {
      const tenderListResponse = await axios.get(`${config.restHost}/api/tenders`, {
        params: {
          projectGuid: auth.getUserInfo().projectGuid
        },
        headers: {
          Authorization: utility.getAuthHeader(auth.getToken())
        }
      })
      this.tenderList = tenderListResponse.data
    }
  },
  async created () {
    this.$loading({})
    await this.prepare()
    await this.getData()
    this.$loading({}).close()
  }
}
</script>

<style scoped>
/deep/ .el-table .cell {
  white-space: pre-line !important;
}

/deep/ .el-cascader {
  width: 100%;
}
</style>
