<template>
  <div class="supervise-examination-record">
    <el-form inline>
      <el-form-item>
        <el-button type="primary" @click="handleSearchClick" :loading="searchLoading">查询</el-button>
        <el-button @click="handleAddClick">添加</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="list" border header-cell-class-name="bg-info text-light">
      <el-table-column align="center" prop="meterageList.meterageDisplayCode" label="子目号"></el-table-column>
      <el-table-column align="center" prop="meterageList.meterageName" label="子目名称"></el-table-column>
      <el-table-column align="center" prop="meterageList.unit" label="单位"></el-table-column>
      <el-table-column align="center" prop="meterageList.price" label="单价"></el-table-column>
      <el-table-column v-for="(detail, index) of dataColumns" :key="detail.superviseExaminationRecordDetailGuid"
                       align="center"
                       :label="`${detail.identityName}数量`">
        <template slot-scope="scope">
          <span>{{ scope.row.superviseExaminationRecordDetails[index].num }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleEditClick(scope.row)">编辑</el-button>
          <el-button type="primary" size="mini" @click="handleDeleteClick(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="编辑" :visible.sync="dialogVisible">
      <el-form :model="form">
        <el-form-item>
          <el-input v-model="form.num" placeholder="请输入数量"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleOkClick" :loading="okLoading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import axios from 'axios'
import auth from '@/common/auth'
import config from '@/config'

export default {
  name: 'SuperviseExaminationRecord',
  components: {},
  props: {},
  data () {
    return {
      list: [],
      superviseExaminationGuid: this.$route.query.superviseExaminationGuid,
      tenderGuid: this.$route.query.tenderGuid,
      okLoading: false,
      searchLoading: false,
      dialogVisible: false,
      form: {
        superviseExaminationRecordGuid: '',
        num: 0.0
      }
    }
  },
  computed: {
    dataColumns () {
      if (this.list.length) {
        return this.list[0].superviseExaminationRecordDetails
      }
      return []
    }
  },
  methods: {
    async handleSearchClick () {
      await this.getData()
    },
    handleEditClick (row) {
      this.form = {
        superviseExaminationRecordGuid: row.superviseExaminationRecordGuid,
        num: row.num
      }
      this.dialogVisible = true
    },
    async handleDeleteClick (row) {
      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          await axios.delete(`${config.restHost}/superviseExaminationRecords/${row.superviseExaminationRecordGuid}`, {
            headers: {
              Authorization: `Bearer ${auth.getToken()}`
            }
          })
        } catch (error) {
          this.$message.error(error.response?.data || '操作失败')
        } finally {
          await this.getData()
        }
      }).catch(() => {
        this.$message.info('已取消删除')
      })
    },
    async handleOkClick () {
      if (!this.form.num) {
        this.$message.error('请输入数量')
        return
      }

      if (isNaN(this.form.num)) {
        this.$message.error('请输入数字')
        return
      }

      try {
        this.okLoading = true
        await axios.post(`${config.restHost}/superviseExaminationRecords/approval/${this.form.superviseExaminationRecordGuid}`, {}, {
          params: {
            num: this.form.num
          },
          headers: {
            Authorization: `Bearer ${auth.getToken()}`
          }
        })
        this.okLoading = false
        this.dialogVisible = false
      } catch (error) {
        this.okLoading = false
        this.$message.error(error.response?.data || '操作失败')
      } finally {
        await this.getData()
      }
    },
    handleAddClick () {
      this.$router.push({
        path: '/SuperviseExaminationRecordExcel',
        query: {
          superviseExaminationGuid: this.superviseExaminationGuid,
          tenderGuid: this.tenderGuid
        }
      })
    },
    async getData () {
      this.searchLoading = true
      const response = await axios.get(`${config.restHost}/superviseExaminationRecords/${this.superviseExaminationGuid}`, {
        params: {},
        headers: {
          Authorization: `Bearer ${auth.getToken()}`
        }
      })

      this.list = response.data.map(item => ({
        ...item,
        superviseExaminationRecordDetails: item.superviseExaminationRecordDetails.sort((a, b) => a.sortId - b.sortId)
      }))
      console.log(this.list)
      this.searchLoading = false
    }
  },
  async mounted () {
    await this.getData()
  }
}
</script>

<style scoped lang="scss">
</style>
