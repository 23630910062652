<template>
  <div class="commence-record-excel">
    <div class="header">
      <el-form inline>
        <el-form-item>
          <el-input-number v-model="periodNumber" step-strictly :step="1" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleSearchClick">查询</el-button>
          <el-button @click="handleSaveClick">保存</el-button>
        </el-form-item>
        <el-form-item>
          当前为第{{ currentPeriodNumber }}期数据
        </el-form-item>
      </el-form>
    </div>
    <div ref="excelContainer" class="excel-container"></div>
  </div>
</template>

<script>
import axios from 'axios'
import auth from '@/common/auth'
import config from '@/config'

export default {
  name: 'SuperviseExaminationRecordExcel',
  components: {},
  data () {
    return {
      periodNumber: 0,
      currentPeriodNumber: 0
    }
  },
  computed: {},
  methods: {
    async handleSearchClick () {
      await this.init()
      this.currentPeriodNumber = this.periodNumber
    },
    async handleSaveClick () {
      const postData = []
      for (let rowIndex = 1; rowIndex <= 21; ++rowIndex) {
        const isValid = parseInt(this.workbook.getSheet(0).getCell(rowIndex, 15).value())
        if (isValid === 1) {
          const item = {
            tenderGuid: this.workbook.getSheet(0).getCell(rowIndex, 0).value().toString(),
            num: parseFloat(this.workbook.getSheet(0).getCell(rowIndex, 14).value())
          }
          postData.push(item)
        }
      }

      if (postData.length === 0) {
        this.$message({
          message: '没有需要保存的数据',
          type: 'warning'
        })
      } else {
        try {
          console.log(postData, 'postData')
          await axios.post(`${config.restHost}/commences/${auth.getUserInfo().projectGuid}/${this.currentPeriodNumber}`, postData, {
            headers: {
              Authorization: `Bearer ${auth.getToken()}`
            }
          })
          this.$message({
            message: '保存成功',
            type: 'success'
          })
        } catch (error) {
          const message = error.response?.data || '保存失败'
          this.$message({
            message,
            type: 'error'
          })
        }
      }
    },
    async init () {
      this.$loading({})

      // 加载excel模版
      const excelTemplate = await axios.get(`${config.restHost}/commences?projectGuid=${auth.getUserInfo().projectGuid}&periodNumber=${this.periodNumber}`, {
        responseType: 'blob'
      })
      this.excelIo = new GC.Spread.Excel.IO()
      this.excelIo.open(excelTemplate.data, async json => {
        if (!this.workbook) {
          this.workbook = new GC.Spread.Sheets.Workbook(this.$refs.excelContainer)
        }
        this.workbook.fromJSON(json)

        // 设置剪贴板模式
        this.workbook.getSheet(0).options.clipBoardOptions = GC.Spread.Sheets.ClipboardPasteOptions.values

        this.$loading({}).close()
      })
    }
  },
  async mounted () {
  }
}
</script>

<style lang="scss" scoped>
.commence-record-excel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  box-sizing: border-box;

  .header {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .excel-container {
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: hidden;
    margin: 0;
    box-sizing: border-box;
  }
}
</style>
