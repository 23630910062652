<template>
  <div class="examination-detail">
    <el-table
      :data="list" border header-cell-class-name="bg-info text-light"
    >
      <el-table-column type="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column prop="configCode" header-align="center" align="center" label="款项编码"></el-table-column>
      <el-table-column prop="configName" header-align="center" align="center" label="款项名称"></el-table-column>
      <el-table-column
        header-align="center"
        :label-class-name="showAddBtn ? 'editable' : ''"
        align="center"
        label="金额">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <editable-table-cell
            @blur="handleBlur(scope.row)"
            v-model="scope.row.amount">
          </editable-table-cell>
        </template>
      </el-table-column>
      <el-table-column v-for="(detail, index) of dataColumns" :key="detail.superviseOtherRecordDetailGuid"
                       align="center"
                       :label="`${detail.identityName}金额`">
        <template slot-scope="scope">
          <span>{{ scope.row.superviseOtherRecordDetails[index].amount }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import EditableTableCell from '@/views/ExaminationManage/Components/EditableTableCell.vue'
import axios from 'axios'
import config from '@/config'
import auth from '@/common/auth'

export default {
  name: 'SuperviseOtherRecord',
  components: {
    EditableTableCell
  },
  computed: {
    dataColumns () {
      if (this.list.length) {
        return this.list[0].superviseOtherRecordDetails
      }
      return []
    }
  },
  data () {
    return {
      list: [],
      superviseExaminationGuid: this.$route.query.superviseExaminationGuid
    }
  },
  methods: {
    async handleBlur (row) {
      try {
        await axios.put(`${config.restHost}/superviseOtherRecords`, {
          ...row
        }, {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`
          }
        })
        this.$message.success('修改成功')
      } catch (error) {
        this.$message.error(error.response?.data || '修改失败')
      } finally {
        await this.getData()
      }
    },
    async getData () {
      const response = await axios.get(`${config.restHost}/superviseOtherRecords/${this.superviseExaminationGuid}`, {
        headers: {
          Authorization: `Bearer ${auth.getToken()}`
        }
      })
      this.list = response.data.map(item => ({
        ...item,
        superviseOtherRecordDetails: item.superviseOtherRecordDetails.sort((a, b) => a.sortId - b.sortId)
      }))
    }
  },
  async created () {
    await this.getData()
  }
}
</script>

<style scoped lang="scss">
.examination-detail {
  position: relative;
  min-width: 780px;
}

.title {
  position: absolute;
  line-height: 40px;
  font-size: 14px;
  top: 0;
  right: 0;
  padding-right: 5px;
  color: red;
}
</style>
