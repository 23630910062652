<template>
  <div class="supervise-examination-record-excel">
    <div class="header">
      <el-form inline>
        <el-form-item>
          <el-button @click="handleSaveClick">保存</el-button>
          <el-button @click="handleGoBackClick">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div ref="excelContainer" class="excel-container"></div>
  </div>
</template>

<script>
import axios from 'axios'
import auth from '@/common/auth'
import config from '@/config'

export default {
  name: 'SuperviseExaminationRecordExcel',
  components: {},
  data () {
    return {
      superviseExaminationGuid: this.$route.query.superviseExaminationGuid,
      tenderGuid: this.$route.query.tenderGuid
    }
  },
  computed: {},
  methods: {
    async handleSaveClick () {
      const postData = []
      for (let rowIndex = 1; rowIndex <= 2001; ++rowIndex) {
        const isValid = parseInt(this.workbook.getSheet(0).getCell(rowIndex, 8).value())
        if (isValid === 1) {
          const item = {
            meterageListGuid: this.workbook.getSheet(0).getCell(rowIndex, 7).value().toString(),
            num: parseFloat(this.workbook.getSheet(0).getCell(rowIndex, 5).value())
          }
          postData.push(item)
        }
      }

      if (postData.length === 0) {
        this.$message({
          message: '没有需要保存的数据',
          type: 'warning'
        })
      } else {
        try {
          await axios.post(`${config.restHost}/superviseExaminationRecords/batchAdd/${this.superviseExaminationGuid}`, postData, {
            headers: {
              Authorization: `Bearer ${auth.getToken()}`
            }
          })
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.$router.go(-1)
        } catch (error) {
          const message = error.response?.data || '保存失败'
          if (message.indexOf('overload') > -1) {
            const meterageDisplayCodeList = message.split('@')[1].split(',')

            const rows = this.workbook.getSheet(0).toJSON().data.dataTable
            const errorRows = []
            for (let rowIndex of Object.keys(rows)) {
              if (meterageDisplayCodeList.indexOf(rows[rowIndex]['1'].value) > -1) {
                errorRows.push({
                  rowIndex: parseInt(rowIndex),
                  cellValue: 1
                })
              } else {
                errorRows.push({
                  rowIndex: parseInt(rowIndex),
                  cellValue: 0
                })
              }
            }
            this.workbook.getSheet(0).setArray(0, 9, errorRows.sort((a, b) => a.rowIndex - b.rowIndex).map(item => item.cellValue))
            this.$message({
              message: `保存失败，以下清单的计量项超量：${meterageDisplayCodeList.join(',')}`,
              type: 'error'
            })
          } else {
            this.$message({
              message: message,
              type: 'error'
            })
          }
        }
      }
    },
    handleGoBackClick () {
      this.$router.go(-1)
    },
    async init () {
      this.$loading({})

      // 加载excel模版
      const excelTemplate = await axios.get(`/excel/supervise-meterage-template.xlsx`, {
        responseType: 'blob'
      })
      this.excelIo = new GC.Spread.Excel.IO()
      this.excelIo.open(excelTemplate.data, async json => {
        if (!this.workbook) {
          this.workbook = new GC.Spread.Sheets.Workbook(this.$refs.excelContainer)
        }
        this.workbook.fromJSON(json)

        const meterageListResponse = await axios.get(`${config.restHost}/meterageList/tenderMeterageList/${this.tenderGuid}`, {
          headers: {
            Authorization: `Bearer ${auth.getToken()}`
          }
        })

        const meterageLists = meterageListResponse.data

        const array = meterageLists.map(defaultList => [
          defaultList.meterageCode,
          defaultList.meterageDisplayCode,
          defaultList.meterageName,
          defaultList.unit,
          defaultList.price,
          defaultList.meterageListGuid
        ])

        this.workbook.getSheet(0).setRowCount(2001)
        this.workbook.getSheet(1).setRowCount(2001)
        this.workbook.getSheet(1).setArray(1, 0, array)
        // 设置剪贴板模式
        this.workbook.getSheet(0).options.clipBoardOptions = GC.Spread.Sheets.ClipboardPasteOptions.values

        this.$loading({}).close()
      })
    }
  },
  async mounted () {
    await this.init()
  }
}
</script>

<style lang="scss" scoped>
.supervise-examination-record-excel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  box-sizing: border-box;

  .header {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .excel-container {
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: hidden;
    margin: 0;
    box-sizing: border-box;
  }
}
</style>
