<template>
  <div class="supervise-period">

    <el-form inline>
      <el-form-item label="标段" prop="tenderGuid">
        <el-select v-model="searchParams.tenderGuid" clearable>
          <el-option v-for="tender of tenderList" :key="tender.tenderGuid" :label="tender.tenderName"
                     :value="tender.tenderGuid"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearchClick" :loading="searchLoading">查询</el-button>
        <el-button @click="handleAddClick">添加</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="list" border header-cell-class-name="bg-info text-light">
      <el-table-column align="center" prop="tender.tenderName" label="标段名称"></el-table-column>
      <el-table-column align="center" prop="supervisePeriodSerialNo" label="工期流水号"></el-table-column>
      <el-table-column align="center" prop="supervisePeriodName" label="工期名称"></el-table-column>
      <el-table-column align="center" prop="meterageDate" label="计量日期"></el-table-column>
      <el-table-column align="center" label="操作">
        <template v-if="!!scope.row.supervisePeriodGuid" slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleEditClick(scope.row)">编辑</el-button>
          <el-button type="primary" size="mini" @click="handleDeleteClick(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="formDialogTitle" :visible.sync="formDialogVisible" destroy-on-close>
      <el-form ref="formRef" :model="supervisePeriod" :rules="rules" label-width="170px">
        <el-form-item label="工期流水号" prop="supervisePeriodSerialNo">
          <el-input-number v-model="supervisePeriod.supervisePeriodSerialNo" :min="0" :step="1" step-strictly
                           placeholder="工期流水号" :disabled="isEdit"></el-input-number>
        </el-form-item>
        <el-form-item label="工期名称" prop="supervisePeriodName">
          <el-input v-model="supervisePeriod.supervisePeriodName" placeholder="工期名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="计量日期" prop="meterageDate">
          <el-date-picker v-model="supervisePeriod.meterageDate" type="date" format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd" placeholder="计量日期" clearable></el-date-picker>
        </el-form-item>
        <el-form-item label="标段" prop="tenderGuid">
          <el-select v-model="supervisePeriod.tenderGuid" placeholder="标段" clearable :disabled="isEdit">
            <el-option v-for="tender of tenderList" :key="tender.tenderGuid" :label="tender.tenderName"
                       :value="tender.tenderGuid"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleOkClick" :loading="okLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import config from '@/config'
import utility from '@/common/utility'
import auth from '@/common/auth'

export default {
  name: 'SupervisePeriod',
  components: {},
  data () {
    return {
      supervisePeriod: {
        supervisePeriodGuid: '',
        supervisePeriodSerialNo: 0,
        supervisePeriodName: '',
        meterageDate: '',
        tenderGuid: ''
      },
      rules: {
        supervisePeriodSerialNo: [
          { required: true, message: '请输入工期流水号', trigger: 'blur' }
        ],
        supervisePeriodName: [
          { required: true, message: '请输入工期名称', trigger: 'blur' }
        ],
        meterageDate: [
          { required: true, message: '请输入计量日期', trigger: 'blur' }
        ],
        tenderGuid: [
          { required: true, message: '请选择标段', trigger: 'blur' }
        ]
      },
      searchParams: {
        tenderGuid: ''
      },
      tenderList: [],
      isAdd: false,
      isEdit: false,
      formDialogVisible: false,
      okLoading: false,
      searchLoading: false,
      list: []
    }
  },
  computed: {
    formDialogTitle () {
      if (this.isAdd) {
        return '添加监理计量工期'
      } else if (this.isEdit) {
        return '编辑监理计量工期'
      } else {
        return ''
      }
    }
  },
  methods: {
    async handleSearchClick () {
      await this.getData()
    },
    handleAddClick () {
      this.supervisePeriod = {
        supervisePeriodGuid: '',
        supervisePeriodSerialNo: 0,
        supervisePeriodName: '',
        meterageDate: '',
        tenderGuid: ''
      }
      this.isAdd = true
      this.isEdit = false
      this.formDialogVisible = true
    },
    handleEditClick (row) {
      this.supervisePeriod = row
      this.isAdd = false
      this.isEdit = true
      this.formDialogVisible = true
    },
    handleDeleteClick (row) {
      this.$confirm(`您确定要删除吗${row.supervisePeriodName}工期吗？`)
        .then(async () => {
          this.$loading({})
          try {
            await axios.delete(`${config.restHost}/supervisePeriods/${row.supervisePeriodGuid}`, {
              headers: {
                Authorization: utility.getAuthHeader(auth.getToken())
              }
            })
            this.$message.success('删除成功')
          } catch (error) {
            this.$message.error(error.response?.data || '删除失败')
          } finally {
            this.$loading({}).close()
            await this.getData()
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleCancel () {
      this.isAdd = false
      this.isEdit = false
      this.formDialogVisible = false
    },
    async handleOkClick () {
      await this.$refs.formRef.validate(async valid => {
        if (!valid) {
          this.$message.error('请检查输入项')
        } else {
          this.okLoading = true
          if (this.isAdd) {
            await this.add()
          }
          if (this.isEdit) {
            await this.edit()
          }
          this.isAdd = false
          this.isEdit = false
          this.formDialogVisible = false
          this.okLoading = false
          await this.getData()
        }
      })
    },
    async add () {
      try {
        await axios.post(`${config.restHost}/supervisePeriods`, this.supervisePeriod, {
          headers: {
            Authorization: utility.getAuthHeader(auth.getToken())
          }
        })

        this.$message.success('添加成功')
      } catch (error) {
        this.$message.error(error.response?.data || '添加失败')
      }
    },
    async edit () {
      try {
        await axios.put(`${config.restHost}/supervisePeriods/${this.supervisePeriod.supervisePeriodGuid}`, this.supervisePeriod, {
          headers: {
            Authorization: utility.getAuthHeader(auth.getToken())
          }
        })

        this.$message.success('编辑成功')
      } catch (error) {
        this.$message.error(error.response?.data || '编辑失败')
      }
    },
    async getData () {
      this.searchLoading = true
      const response = await axios.get(`${config.restHost}/supervisePeriods/${auth.getUserInfo().projectGuid}`, {
        params: { ...this.searchParams },
        headers: {
          Authorization: utility.getAuthHeader(auth.getToken())
        }
      })
      this.list = response.data
      this.searchLoading = false
    },
    async prepare () {
      const tenderListResponse = await axios.get(`${config.restHost}/api/tenders`, {
        params: {
          projectGuid: auth.getUserInfo().projectGuid
        },
        headers: {
          Authorization: utility.getAuthHeader(auth.getToken())
        }
      })
      this.tenderList = tenderListResponse.data
    }
  },
  async created () {
    this.$loading({})
    await this.prepare()
    await this.getData()
    this.$loading({}).close()
  }
}
</script>

<style scoped>
/deep/ .el-table .cell {
  white-space: pre-line !important;
}
</style>
